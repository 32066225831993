import Footer from "components/Footer";
import Header from "components/Header";

interface ContactUsProps {}

const ContactUs = ({}: ContactUsProps) => {
  return (
    <>
      <Header />

      {/* main section */}
      <main className="w-full  text-pro_text_dark ">
        <div className="pt-[120px] px-[20px] lg:px-[160px] flex flex-col gap-12">
          <p className="text-[50px] lg:text-[70px] font-Avenir_bold">
            CONTACT <span className="text-dashboard_secondary">US</span>
          </p>
          <p className="text-[30px] lg:text-[50px] font-Avenir_bold">
            We're Here to Help You Kickstart Your Career!
          </p>
          <p className="text-[20px] lg:text-[30px] font-Avenir_regular">
            Have questions about job opportunities, internships, or how to get
            started with your career journey? We’re here to assist you every
            step of the way! Whether you’re an intern, a recent graduate, or a
            company looking to hire fresh talent, our team is dedicated to
            helping you find the right path.
          </p>

          <div className="flex items-center gap-16">
            <a
              href="mailto:contact@intrn.app"
              className="py-[10px] px-[40px] bg-gray-300 text-[15px] lg:text-[25px] font-Avenir_bold hover:bg-pro_text_dark hover:text-pro_text_light duration-300 rounded-[15px] cursor-pointer text-pro_text_dark flex items-center justify-center"
            >
              Via Email
            </a>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ContactUs;
