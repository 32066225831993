import {
  APPLICATIONS_PATH,
  COMPANIES_HOME_PATH,
  COMPANIES_PATH,
  COMPANIES_VACANCIES_PATH,
  COMPANY_DETAILS_PATH,
  CREATE_VACANCY_PATH,
  HOME_PATH,
  JOB_DETAILS_PATH,
  MATCHED_INTERNSHIPS_PATH,
  PROFILE_PATH,
  SEARCH_RESULTS_PATH,
  SUB_PLANS,
  ERROR_PATH,
  UPDATE_VACANCY_PATH,
  COMPANY_PROFILE_PATH,
  COMPANY_APPLICATIONS_PATH,
  DOWNLOAD_CV_PATH,
  COMPANY_VIEW_APPLICATION_PATH,
  SUBSCRIPTION_FINISH,
  CONTACT_US_PATH,
} from "Routes/paths";
import Error from "pages/Error";
import Home from "pages/Home";
import MatchedInternships from "pages/MatchedInternships";
import SearchResults from "pages/SearchResults";
import Profile from "pages/Profile";
import Applications from "pages/Applications";
import JobDetails from "pages/JobDetails";
import Companies from "pages/Companies";
import CompanyDetails from "pages/CompanyDetails";
import SubPlans from "pages/SubPlans";
import CompaniesHome from "pages/Dashboard/CompaniesHome";
import DashboardParent from "pages/Dashboard/DashboardParent";
import Vacancies from "pages/Dashboard/Vacancies/Vacancies";
import CreateVacancy from "pages/Dashboard/Vacancies/CreateVacancy";
import UpdateVacancy from "pages/Dashboard/Vacancies/UpdateVacancy";
import CompanyProfile from "pages/Dashboard/CompanyProfile/CompanyProfile";
import CompanyApplications from "pages/Dashboard/Applications/Applications";
import CvDownloader from "components/CV/CvDownloader";
import UserProtectedParent from "pages/UserProtectedRoutes/UserProtectedParent";
import ViewApplication from "pages/Dashboard/Applications/ViewApplication";
import Subscription from "pages/Subscription";
import ContactUs from "pages/ContactUs";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: ERROR_PATH,
    element: <Error />,
  },
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: CONTACT_US_PATH,
    element: <ContactUs />,
  },
  {
    path: MATCHED_INTERNSHIPS_PATH,
    parent: <UserProtectedParent />,
    element: <MatchedInternships />,
  },
  {
    path: SEARCH_RESULTS_PATH,
    // parent: <UserProtectedParent />,
    element: <SearchResults />,
  },
  {
    path: PROFILE_PATH,
    parent: <UserProtectedParent />,
    element: <Profile />,
  },
  {
    path: APPLICATIONS_PATH,
    parent: <UserProtectedParent />,
    element: <Applications />,
  },
  {
    path: JOB_DETAILS_PATH,
    // parent: <UserProtectedParent />,
    element: <JobDetails />,
  },
  {
    path: `${JOB_DETAILS_PATH}/:Id`,
    // parent: <UserProtectedParent />,
    element: <JobDetails />,
  },
  {
    path: COMPANIES_PATH,
    // parent: <UserProtectedParent />,
    element: <Companies />,
  },
  {
    path: COMPANY_DETAILS_PATH,
    // parent: <UserProtectedParent />,
    element: <CompanyDetails />,
  },
  {
    path: SUB_PLANS,
    element: <SubPlans />,
  },
  {
    path: COMPANIES_HOME_PATH,
    element: <CompaniesHome />,
    parent: <DashboardParent />,
  },
  {
    path: COMPANIES_VACANCIES_PATH,
    element: <Vacancies />,
    parent: <DashboardParent />,
  },
  {
    path: CREATE_VACANCY_PATH,
    element: <CreateVacancy />,
    parent: <DashboardParent />,
  },
  {
    path: UPDATE_VACANCY_PATH,
    element: <UpdateVacancy />,
    parent: <DashboardParent />,
  },
  {
    path: COMPANY_PROFILE_PATH,
    element: <CompanyProfile />,
    parent: <DashboardParent />,
  },
  {
    path: COMPANY_APPLICATIONS_PATH,
    element: <CompanyApplications />,
    parent: <DashboardParent />,
  },
  {
    path: COMPANY_VIEW_APPLICATION_PATH,
    element: <ViewApplication />,
    parent: <DashboardParent />,
  },
  {
    path: DOWNLOAD_CV_PATH,
    element: <CvDownloader />,
  },
  {
    path: SUBSCRIPTION_FINISH,
    element: <Subscription />,
  },
];
